import React, { createContext, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'

import {
  setJwtString,
  cleanStorage,
  jwtCheck,
  getJwtExp
} from './components/Auth'
import { getLoggedUser } from './services/api/get-logged-user'
import { getCalls } from './services/api/calls'

export const Context = createContext({
  isAuth: false,
  loggedUser: {}
})

const Provider = ({ children }) => {
  // indica si hay una sesión activa
  const [isAuth, setIsAuth] = useState(jwtCheck())

  // cantidad de segundos desde login o click anti-logout
  const [loginTime, setLoginTime] = useState(0)

  // cantidad de segundos de actividad antes de mostrar modal de cerrar sesión
  const minSecondsBeforeLogout = 3600

  // cantidad de segundos que espera el modal antes de cerrarse sola la sesión
  const waitSecondsBeforeLogout = 300

  // usuario de jwt
  const [loggedUser, setContextUser] = useState(() => {
    return JSON.parse(window.localStorage.getItem('ldv-user'))
  })

  const setLoggedUser = user => {
    setContextUser(user)
    window.localStorage.setItem('ldv-user', JSON.stringify(user))
  }

  // aumenta la cantidad de segundos de actividad
  useEffect(() => {
    if (!isAuth) return

    // segundos que faltan para expirar el JWT
    const jwtExp = getJwtExp()
    if (jwtExp <= 0) {
      value.logout()
    } else {
      if (jwtExp === 300) {
        toast.info(
          'Tu login vence dentro de 5 minutos, vuelve a iniciar sesión para renovarlo.'
        )
      } else if (jwtExp === 600) {
        toast.info(
          'Tu login vence dentro de 10 minutos, vuelve a iniciar sesión para renovarlo.'
        )
      }
    }

    const logoutAttempt = async () => {
      const killTime = minSecondsBeforeLogout + waitSecondsBeforeLogout

      if (loginTime > minSecondsBeforeLogout && loginTime % 60 === 0) {
        const call = await getCalls({
          author: loggedUser.id,
          order_by: 'created_at desc',
          page: 1,
          per_page: 1
        })

        if (call && call.length > 0) {
          const date1 = moment()
          const date2 = moment(call[0].created_at)
          const diff = date2.diff(date1, 'hours', true)

          if (diff <= 1) {
            setLoginTime(0)
          }
        }
      }

      if (loginTime >= killTime) {
        // cerra la sesión
        value.logout()
      }

      setLoginTime(loginTime => loginTime + 1)
    }

    const interval = setInterval(logoutAttempt, 1000)

    return () => clearInterval(interval)
  }, [loginTime, isAuth])

  const value = {
    isAuth,
    loggedUser,
    setLoggedUser,
    loginTime,
    setLoginTime,
    minSecondsBeforeLogout,
    waitSecondsBeforeLogout,

    // elimina los items de localStorage que inician con "numera-"
    logout: () => {
      cleanStorage()
      setIsAuth(false)
    },

    // guarda jwt y loggedUser en localStorage
    activateAuth: async jwt => {
      setJwtString(jwt)

      const u = await getLoggedUser()
      setLoggedUser(u.data[0])

      setIsAuth(true)
    }
  }

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default {
  Provider,
  Consumer: Context.Consumer
}
