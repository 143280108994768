import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @param {Object} args - Argumentos a pasar en query
 * @returns {Promise}
 */
export function getCalls (args) {
  const params = querystring.stringify(args)

  return axios.get(API('calls', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Envía al backend una llamada. Permite crear o actualizar.
 * @param {Object} body - Datos de la llamada a crear
 */
export function putCall (body) {
  return axios.put(API('calls'), body, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Envía al backend la duración de la llamada
 * @param {int} seconds - Cantidad de segundos
 */
export function putDuration (callID, seconds) {
  return axios.put(API('calls', 'action=duration'), {
    id: callID,
    duration: seconds
  }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
