import axios from 'axios'
import jwt, { getJwtString } from '../../components/Auth'
import API from '../../components/API'

/**
 * @returns {Promise}
 */
export function getLoggedUser () {
  const userId = jwt().payload.user_id
  return axios.get(API('users', `id=${userId}`), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    }
  })
}
