// Retorna la URL completa hacia el recurso del API
// uri string no debe incluir el slash del inicio
// si el entorno es testing agrega el sufijo automáticamente
const API = (uri, queryParams = '') => {
  const url = `${process.env.API_URL}`

  if (process.env.ENV === 'testing') {
    uri += '_testing'
  }

  return `${url}/${uri}?${queryParams}`
}

export default API
